input,
textarea {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px;
}

input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group {
  clear: both;
  margin: 15px 0;
  position: relative;
}

.input-group input[type='button'] {
  background-color: rgb(37, 37, 37);
  min-width: 50px;
  width: auto;
  color: white;
  font-size: 35px;
}

.input-group .button-minus,
.input-group .button-plus {
  font-weight: bold;
  height: 50px;
  padding: 0;
  width: 50px;
  position: relative;
}

.input-group .quantity-field {
  position: relative;
  height: 50px;
  left: -6px;
  text-align: center;
  width: 180px;
  display: inline-block;
  font-size: 35px;
  margin: 0 0 3px;
  resize: vertical;
}

.button-plus {
  left: -13px;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}


.App {
  text-align: center;
}

.App-header {
  background-color: rgb(37, 37, 37);
  /* min-height: 90vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {

  align-items: center;
  justify-content: center;
  font-size: 20pt;
  color: white;

}

/* .typeFont{

  letterSpacing: '0.3em', fontSize : '24px', lineHeight:1.6, fontWeight : 400
} */

html, body
{
    height: 100%;
}